export const ARTICLE_PAGE_TEST_IDS = {
  AUTHORS: 'article-authors',
  AUTHORS_BIO_CARDS: 'authors-bio-cards',
  AUTHORS_BIO_SECTION_TITLE: 'authors-bio-section-title',
  AUTHOR_AND_DATE: 'article-author-and-date',
  BRANDED_ARTICLE: 'branded-article',
  CAPTION: 'caption',
  CONTAINER_EYEBROW: 'article-container-eyebrow',
  CONTENT: 'article-content',
  CREDIT: 'credit',
  EYEBROW: 'article-eyebrow',
  HEADER_DATE: 'header-date',
  IMAGE: 'article-image',
  JUMPTO: 'jump-to',
  POST_LIST: 'post-list',
  RIGHT_RAIL: 'article-right-rail',
  SCHOOL_PAGE_ADDRESS: 'school-page-address',
  SCHOOL_PAGE_BANNER: 'school-page-banner',
  SCHOOL_PAGE_LINK: 'school-page-link',
  SCHOOL_PAGE_PROGRAM_CARD_LINK: 'school-program-card-link',
  SCHOOL_PAGE_PROGRAM_CARD_SUMMARY: 'school-program-card-summary',
  SCHOOL_PAGE_PROGRAM_CARD_TABLE: 'school-program-card-table',
  SCHOOL_PAGE_PROGRAM_CARD_TD: 'school-program-card-td',
  CONTAINER: 'article-container',
  SCHOOL_PAGE_PROGRAM_CARD_TH: 'school-program-card-th',
  SCHOOL_PAGE_PROGRAM_CARD_TITLE: 'school-program-card-title',
  SCHOOL_PAGE_PROGRAM_CARD_TR: 'school-program-card-tr',
  SCHOOL_PAGE_RIGHT_RAIL: 'school-page-right-rail',
  SCHOOL_PAGE_TITLE: 'school-page-title',
  SECTION_EYEBROW: 'article-section-eyebrow',
  SERIES_LIST: 'article-series-list',
  SERIES_TOP: 'article-series-top',
  TAG_EYEBROW: 'article-tag-eyebrow',
  TIME_AGO: 'time-ago-wrapper',
  TITLE: 'article-title',
  WRAPPER: 'article-wrapper',
};

export type TestIds = {
  [key in keyof typeof ARTICLE_PAGE_TEST_IDS]?: string;
};

const getArticlePageTestId = (value: keyof TestIds) => ARTICLE_PAGE_TEST_IDS[value];

export default getArticlePageTestId;
